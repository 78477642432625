import React, { useState } from 'react';
import './services.css';
import { HiOutlineClipboardList, HiOutlineArrowSmRight, HiOutlineCheckCircle, HiX } from 'react-icons/hi';

const Services = () => {
    const [toggleState, setToggleState] = useState(0);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    return (
      <section className="services section" id="services">
        <h2 className="section__title">Services</h2>
        <span className="section__subtitle">Create + Collaborate</span>

        <div className="services__container container grid">
          <div className="services__content">
            <div>
              <HiOutlineClipboardList className="services__icon" />
              <h3 className="services__title">
                Backend
                <br />
                Development
              </h3>
            </div>
            <span className="services__button" onClick={() => toggleTab(1)}>
              View More
              <HiOutlineArrowSmRight className="services__button-icon" />
            </span>
            <div
              className={
                toggleState === 1
                  ? "services__modal active-modal"
                  : "services__modal"
              }
            >
              <div className="services__modal-content">
                <HiX
                  onClick={() => toggleTab(0)}
                  className="services__modal-close"
                />
                <h3 className="services__modal-title">Create + Collaborate</h3>
                <p className="services__modal-description">
                  Robust and scalable backend solutions for web and mobile
                  applications.
                </p>
                <ul className="services__modal-services grid">
                  <li className="services__modal-service">
                    <HiOutlineCheckCircle className="services__modal-icon" />
                    <p className="services__modal-info">
                      Web page and app development
                    </p>
                  </li>
                  <li className="services__modal-service">
                    <HiOutlineCheckCircle className="services__modal-icon" />
                    <p className="services__modal-info">
                      Integrate creative colloboration
                    </p>
                  </li>
                  <li className="services__modal-service">
                    <HiOutlineCheckCircle className="services__modal-icon" />
                    <p className="services__modal-info">
                      Provide product mockups
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="services__content">
            <div>
              <HiOutlineClipboardList className="services__icon" />
              <h3 className="services__title">
                API
                <br />
                Integration
              </h3>
            </div>
            <span className="services__button" onClick={() => toggleTab(2)}>
              View More
              <HiOutlineArrowSmRight className="services__button-icon" />
            </span>
            <div
              className={
                toggleState === 2
                  ? "services__modal active-modal"
                  : "services__modal"
              }
            >
              <div className="services__modal-content">
                <HiX
                  onClick={() => toggleTab(0)}
                  className="services__modal-close"
                />
                <h3 className="services__modal-title">Connect + Streamline</h3>
                <p className="services__modal-description">
                  Seamless API integration and management to enhance your
                  application's capabilities.
                </p>
                <ul className="services__modal-services grid">
                  <li className="services__modal-service">
                    <HiOutlineCheckCircle className="services__modal-icon" />
                    <p className="services__modal-info">
                      Seamless API Interactions
                    </p>
                  </li>
                  <li className="services__modal-service">
                    <HiOutlineCheckCircle className="services__modal-icon" />
                    <p className="services__modal-info">
                      Efficient Code Integration
                    </p>
                  </li>
                  <li className="services__modal-service">
                    <HiOutlineCheckCircle className="services__modal-icon" />
                    <p className="services__modal-info">Accessible Solutions</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="services__content">
            <div>
              <HiOutlineClipboardList className="services__icon" />
              <h3 className="services__title">
                Database
                <br />
                Management
              </h3>
            </div>
            <span className="services__button" onClick={() => toggleTab(3)}>
              View More
              <HiOutlineArrowSmRight className="services__button-icon" />
            </span>
            <div
              className={
                toggleState === 3
                  ? "services__modal active-modal"
                  : "services__modal"
              }
            >
              <div className="services__modal-content">
                <HiX
                  onClick={() => toggleTab(0)}
                  className="services__modal-close"
                />
                <h3 className="services__modal-title">Organize + Optimize</h3>
                <p className="services__modal-description">
                  Efficient database design, management, and optimization for
                  secure and reliable data handling.
                </p>
                <ul className="services__modal-services grid">
                  <li className="services__modal-service">
                    <HiOutlineCheckCircle className="services__modal-icon" />
                    <p className="services__modal-info">
                      Structured Data Management
                    </p>
                  </li>
                  <li className="services__modal-service">
                    <HiOutlineCheckCircle className="services__modal-icon" />
                    <p className="services__modal-info">
                      Optimized Performance
                    </p>
                  </li>
                  <li className="services__modal-service">
                    <HiOutlineCheckCircle className="services__modal-icon" />
                    <p className="services__modal-info">Secure Data Handling</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Services;