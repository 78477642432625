import Image1 from "../../assets/woman1.png";
import Image2 from "../../assets/man.png";
import Image3 from "../../assets/woman2.png";

export const Data = [
  {
    id: 1,
    image: Image1,
    title: "Beata K. - Boutiqueroselle",
    description:
      "Mycket bra service ända in på småtimmarna! Rekommenderas stark för er som vill bygga/utveckla era hemsidor!",
  },
  {
    id: 2,
    image: Image2,
    title: "Sedat -sushiarenan",
    description: "Jag är mycket nöjd med både service och hemsida!",
  },
  {
    id: 3,
    image: Image2,
    title: "Milad - kvarnensbarokok",
    description:
      "Proaktiva, engagerade och kompetenta! Mycket nöjd med resultatet och kommer fortsätta samarbeta med Mikael.",
  },
  {
    id: 4,
    image: Image2,
    title: "Gabriel - fuzionpouches.com",
    description:
      "Kompetent och snabb hjälp på de frågor vi haft! Funkat jättebra.",
  },
];