import Project1 from "../../assets/sushiarenan.gif";
import Project2 from "../../assets/fuzionpouches.png";
import Project3 from "../../assets/bygglovskontoret.png";
import Project4 from "../../assets/kvarnensbarokok.png";
import Project5 from "../../assets/hmdserviceab.png";
import Project6 from "../../assets/boutiqueroselle.png";

export const projectsData = [
  {
    id: 1,
    image: Project1,
    title: "Sushiarenan.se",
    category: "S",
    link: "https://sushiarenan.se",
  },
  {
    id: 2,
    image: Project2,
    title: "Fuzionpouches.se",
    category: "F",
    link: "https://fuzionpouches.com",
  },
  {
    id: 3,
    image: Project3,
    title: "Bygglovskontoret.se",
    category: "B",
    link: "https://bygglovskontoret.se",
  },
  {
    id: 4,
    image: Project4,
    title: "kvarnensbarokok.se",
    category: "K",
    link: "https://kvarnensbarokok.se",
  },
  {
    id: 5,
    image: Project5,
    title: "hmdserviceab.se",
    category: "H",
    link: "https://hmdserviceab.se",
  },
  {
    id: 6,
    image: Project6,
    title: "boutiqueroselle.com",
    category: "B",
    link: "https://boutiqueroselle.com",
  },
];

export const projectsNav = [
  {
    name: "All",
  },
  {
    name: "B",
  },
  {
    name: "S",
  },
  {
    name: "F",
  },
  {
    name: "H",
  },
  {
    name: "K",
  },
];
