import React from "react";
import { HiCheckBadge } from "react-icons/hi2";

const Backend = () => {
    return (
      <div className="skills__content">
        <h3 className="skills__title">Backend Developer</h3>
        <div className="skills__box">
          <div className="skills__group">
            <div className="skills__data">
              <HiCheckBadge />
              <div>
                <h3 className="skills__name">C# & .NET Core</h3>
                <span className="skills__level"></span>
              </div>
            </div>
            <div className="skills__data">
              <HiCheckBadge />
              <div>
                <h3 className="skills__name">ASP.NET Coret</h3>
                <span className="skills__level"></span>
              </div>
            </div>
            <div className="skills__data">
              <HiCheckBadge />
              <div>
                <h3 className="skills__name">Entity Framework</h3>
                <span className="skills__level"></span>
              </div>
            </div>
          </div>
          <div className="skills__group">
            <div className="skills__data">
              <HiCheckBadge />
              <div>
                <h3 className="skills__name">RESTful APIs</h3>
                <span className="skills__level"></span>
              </div>
            </div>
            <div className="skills__data">
              <HiCheckBadge />
              <div>
                <h3 className="skills__name">SQL & MySQL</h3>
                <span className="skills__level"></span>
              </div>
            </div>
            <div className="skills__data">
              <HiCheckBadge />
              <div>
                <h3 className="skills__name">Azure</h3>
                <span className="skills__level"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Backend;